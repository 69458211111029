import { Component, OnInit } from '@angular/core';
import { BasketService } from './basket/basket.service';
import { AccountService } from './account/account.service';
import { Meta,Title } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
   constructor(private basketService: BasketService, private accountService: AccountService,private meta: Meta,private title: Title) {
    this.meta.addTags([
      {name: 'description', content: 'convert photo to video'},
      {name: 'author', content: 'photo-magic'},
      {name: 'keywords', content: 'photo personalization, photo customization'}
    ]);
    this.setTitle('photo personalization');
   }
   public setTitle( newTitle: string) {
    this.title.setTitle( newTitle );
  }
   ngOnInit(): void {
    this.loadBasket();
    this.loadCurrentUser();
   }

   loadCurrentUser() {
     const token = localStorage.getItem('token');
     this.accountService.loadCurrentUser(token).subscribe(() => {
      }, (err) => console.log(err));
   }

   loadBasket() {
    const basketId = localStorage.getItem('basket_id');
    if (basketId) {
      this.basketService.getBasket(basketId).subscribe(() => {
       }, error => console.log(error));
    }
   }
}
