<div class="container">
    <ul class="nav nav-pills nav-justified">
        <!-- steps coming from cdkStepper -->
        <li class="nav-item" *ngFor="let step of steps; let i = index;">
            <button 
            (click)="onClick(i)"
            [disabled]="true"
            [class.active]="selectedIndex === i"
            class="nav-link text-uppercase font-weight-bold btn-block" style="padding: 1.20em;">
            {{step.label}}
        </button>  
        </li>
    </ul>
    <div>
        <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
    </div>
</div>
