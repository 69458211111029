<ng-container *ngIf="items.length > 0">
    <div class="table-responsive">
        <table class="table table-borderless">
            <thead [class.thead-light]="isBasket || isOrder" class="border-0 py-2">
                <tr>
                   <th scope="col">
                        <div class="p-2 px-3 text-uppercase">Product</div>
                   </th>
                   <th scope="col">
                        <div class="py-2 text-uppercase">Price</div>
                   </th>
                   <th scope="col">
                        <div class="py-2 text-uppercase">Quantity</div>
                   </th>
                    <th scope="col">
                        <div class="py-2 text-uppercase">Total</div>
                    </th>
                    <th *ngIf="isBasket" scope="col" class="border-0">
                        <div class="py-2 text-uppercase">Remove</div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of items">
                    <th scope="row">
                        <div class="p-2">
                           <img src="{{item.pictureUrl}}" alt="{{item.productName}}" class="img-fluid" style="max-height:50px;">
                           <div class="ml-3 d-inline-block align-middle">
                               <h5 class="mb-0">
                                   <a routerLink="/shop/{{item.id || item.productId}}" class="text-dark">{{item.productName}}</a>
                               </h5>
                               <span *ngIf="item.type" class="text-muted font-weight-normal font-italic d-bloc">Type: {{item.type}}</span>
                           </div>
                        </div>
                    </th>
                    <td class="align-middle"><strong>{{item.price | currency: 'EUR'}}</strong></td>
                    <td class="align-middle">
                        <div class="d-flex align-items-center" [class.justify-content-center]="!isBasket">
                            <i *ngIf="isBasket" (click)="decrementItem(item)" class="fa fa-minus-circle text-warning mr-2" style="cursor: pointer; font-size: 2em;"></i>
                            <span class="font-weight-bold" style="font-size: 1.5em;">{{item.quantity}}</span>
                            <i *ngIf="isBasket" (click)="incrementItem(item)" class="fa fa-plus-circle text-warning mx-2" style="cursor: pointer; font-size: 2em;"></i>
                        </div>
                    </td>
                    <td class="align-middle">
                        <strong>{{item.price * item.quantity | currency: 'EUR'}}</strong>
                    </td>
                    <td class="align-middle text-center">
                        <a class="text-danger">
                            <i *ngIf="isBasket" (click)="removeBasketItem(item)" class="fa fa-trash" aria-hidden="true" style="cursor: pointer; font-size: 2em;"></i>
    
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-container>
