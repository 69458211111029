//import {v4 as uuidv4} from 'uuid';
//import { uuid } from 'uuidv4';
import { Guid } from 'guid-ts';
export interface IBasket {
    id: string;
    items: IBasketItem[];
    clientSecret?: string;
    paymentIntentId?: string;
    deliveryMethodId?: number;
    shippingPrice?: number;
  }

export interface IBasketItem {
    id: string;
    productId: number;
    //productName: string;
    price: number;
    quantity: number;
    pictureUrl: string;
    brand: string;
    type: string;
  }

export class CustomerBasket implements IBasket {
    id = "";   //"122222222222222dddd";   // uuid();
    items: IBasketItem[] = [];
   }

export interface IBasketTotals {
  shipping: number;
  subtotal: number;
  total: number;
}
