<div class="container marketing">
    <hr class="featurette-divider">
  
    <div class="row featurette">
      <div class="col-md-10">
        <h4>1. Introduction</h4>
        <p>Welcome to photo-magic.nl ("we," "our," or "us"). By using our services, which include photo and video personalize services, 
        you ("user" or "you") agree to comply with and be bound by the following Terms of Service ("Terms"). Please read them carefully.</p>

        <h4>2. Service Overview</h4>
        <p>We provide a platform where users can upload images/photos, which will be customized to specific video style. 
        We reserve the right to modify or discontinue the service at any time without prior notice.</p>

        <h4>3. User Responsibilities</h4>
        <h5>3.1 Account</h5>
        <p>To use certain features, you may be required to create an account. 
        You are responsible for maintaining the confidentiality of your account details, 
        including passwords, and for all activities that occur under your account.</p>

        <h5>3.2 Content Upload</h5>
        <p>By uploading content (photos, videos, etc.) to our platform, you affirm that:

        You own or have the necessary rights to the content you upload.
        The content does not violate any copyright, trademark, privacy, or other proprietary rights of third parties.
        The content is not illegal, defamatory, obscene, or harmful.</p>
        <h4>4. Intellectual Property Rights</h4>
        <h5>4.1 User Content</h5>
        <p>By submitting content to our service, you grant us a non-exclusive, royalty-free, 
            worldwide license to use, modify, reproduce, distribute, and display your content for the purpose of 
            providing the service.</p>

        <h5>4.2 Ownership</h5>
        <p>All customization outputs, including modified photos and videos, remain the intellectual property of 
            the original content owner. However, photo-magic.nl reserves the right to display completed work 
            for promotional purposes, unless explicitly requested otherwise by the user.</p>

        <h4>5. Payment and Pricing</h4>
        <h5>5.1 Fees</h5>
        <p>All services are subject to applicable fees, which will be quoted to user after evaluation 
            of uploaded content. Payment is required before the service is delivered to the user.</p>

        <h5>5.2 Refunds</h5>
        <p>Refunds will only be issued under specific circumstances, such as non-delivery of services or 
            errors made during personalization. Refund requests must be made within 7 days of receiving the completed order.</p>

        <h4>6. Prohibited Activities</h4>
        <p>Users are prohibited from:

        Uploading content that contains nudity, hate speech, violence, or is otherwise inappropriate.
        Using the service for illegal activities.
        Attempting to exploit vulnerabilities or interfere with our systems.</p>
        <h4>7. Data Protection & Privacy</h4>
        <p>We respect your privacy and are committed to protecting your personal data. 
            Please review our [Privacy Policy] for detailed information on how we collect, use, and protect your data.</p>

        <h4>8. Limitation of Liability</h4>
        <p>To the fullest extent permitted by law, photo-magic.nl and its employees, affiliates, 
            or agents shall not be liable for any direct, indirect, incidental, or consequential damages 
            arising from your use of our services.</p>

        <h4>9. Indemnification</h4>
        <p>You agree to indemnify and hold photo-magic.nl harmless from any claims, 
            losses, or damages arising from your use of our service, your content uploads, or your breach of these Terms.</p>

        <h4>10. Termination</h4>
        <p>We reserve the right to terminate or suspend your access to the service, without prior notice, 
            for conduct that we believe violates these Terms or is harmful to other users or the business.</p>

        <h4>11. Changes to Terms of Service</h4>
        <p>We may update these Terms periodically. Your continued use of the service after any changes signifies 
            your acceptance of the new Terms.</p>

        <h4>12. Governing Law</h4>
        <p>These Terms will be governed by and construed in accordance with the laws of the Netherlands, 
            without regard to its conflict of law provisions.</p>

        <h4>13. Contact Information</h4>
        <p>If you have any questions or concerns about these Terms, please contact us at service@photo-magic.nl.</p>
      </div>
    </div>
      
</div>


