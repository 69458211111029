import { Component, OnInit } from '@angular/core';
import { Meta,Title } from '@angular/platform-browser';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private meta: Meta,private title: Title) {
    this.meta.addTags([
      {name: 'description', content: 'convert photo to video'},
      {name: 'author', content: 'photo-magic'},
      {name: 'keywords', content: 'photo personalization, photo customization'}
    ]);
    this.setTitle('photo personalization');
  }

  ngOnInit(): void {
  }
  public setTitle( newTitle: string) {
    this.title.setTitle( newTitle );
  }
}
