<pagination 
    [boundaryLinks]="true"
    [totalItems]="totalCount"
    (pageChanged)="onPagerChange($event)"
    [ngModel]="pageNumber"
    [itemsPerPage]="pageSize"
    previousText="&lsaquo;"
    nextText="&rsaquo;"
    firstText="&laquo;"
    lastText="&raquo;"> 
</pagination>