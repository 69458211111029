<!--
<carousel>
    <slide>
        <img src="assets/images/hero1.jpg" alt="first slide" style="display: block; width: 100%; height: 100%;">
    </slide>
    <slide>
       <img src="assets/images/hero2.jpg" alt="second slide" style="display: block; width: 100%; height: 100%;">
    </slide>
    <slide>
         <img src="assets/images/hero3.jpg" alt="third slide" style="display: block; width: 100%; height: 100%;">
    </slide>
    <slide>
      <img src="assets/images/hero4.jpg" alt="third slide" style="display: block; width: 100%; height: 100%;">
    </slide>
    <slide>
      <img src="assets/images/hero5.jpg" alt="third slide" style="display: block; width: 100%; height: 100%;">
    </slide>
    <slide>
      <img src="assets/images/hero6.jpg" alt="third slide" style="display: block; width: 100%; height: 100%;">
    </slide>
</carousel>
-->
<div class="container marketing">
  <!--<video width="500" height="500" loop autoplay muted>  -->
  <video width="640" height="426" muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
    <source src="assets/videos/war-field.mp4" type="video/mp4">
    Your browser does not support the video tag.
  </video>
</div>

<div class="container marketing">
  <!--
  <section>
    <div class="d-flex justify-content-center pt-4">
        <h1>Welcome to Sports Center!!!</h1>
    </div>
  </section>
  -->
     <hr class="featurette-divider">

    <div class="row featurette">
      <div class="col-md-7">
        <h1>Welcome to Photo Magic</h1>
        <!--
        <p class="lead">We offer a service that transforms your photo into customized video using advanced programming. 
          <br> With a variety of video styles to choose from, you can simply select your preferred style and upload your photo. A video matching the chosen style will then be created using your photo.

          <br>The video can be played as screensaver, displayed in digital photo frame, on outdoor digital billboard, and more.</p>
          -->
          <p class="lead">We developed an inovative way to transform photo into customized video using advanced programming. 
            <br><br> 
            With a variety of video styles to choose from, photo can be turned into video matches the chosen style. 
            The video can be played as screensaver, displayed in digital photo frame, on outdoor digital billboard, and more.</p>
      </div>
      <div class="col-md-5">
        <div class="d-flex align-items-center justify-content-center hover-overlay">
        <a [routerLink]="['/video']" class="btn btn-outline-primary py-2 btn-block"><strong>Explore Video Styles</strong></a> 
        </div>
        
      </div>
    </div>
    
  </div>