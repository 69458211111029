<div class="bg-info px-4 text-uppercase font-weight-bold" style="padding: 1.20em;">
    Order Details
</div>
<div class="p-4">
    <p class="font-italic mb-4">Shipping costs will vary.</p>
    <ul class="list-unstyled mb-4">
        <li class="d-flex justify-between py-3 border-bottom">
            <strong class="text-muted">Order SubTotal: </strong>
            <strong>{{subtotal | currency: 'EUR'}}</strong>
        </li>
        <li class="d-flex justify-between py-3 border-bottom">
            <strong class="text-muted">Shipping and Handling: </strong>
            <strong>{{shippingPrice | currency: 'EUR'}}</strong>
        </li>
        <li class="d-flex justify-between py-3 border-bottom">
            <strong class="text-muted">Total: </strong>
            <strong>{{total | currency: 'EUR'}}</strong>
        </li>
    </ul>
</div>