<div class="container">
  <footer class="pt-4 my-md-5 pt-md-5 border-top">    
      <div class="row">
        <div class="col-6 col-md">
          <h5>Shop</h5>
          <ul class="list-unstyled text-small">
            <li><a class="text-muted" routerLink="/shop" routerLinkActive="active">Photos</a></li>
            <li><a class="text-muted" routerLink="/video" routerLinkActive="active">Videos</a></li>            
            <li><a class="text-muted" routerLink="/personalize" routerLinkActive="active">Personalized Service</a></li>            
          </ul>
        </div>
        <div class="col-6 col-md">
          <h5>Policies</h5>
          <ul class="list-unstyled text-small">
            <li><a class="text-muted" routerLink="/privacy" routerLinkActive="active">Privacy Notice</a></li>
            <li><a class="text-muted" routerLink="/service-term" routerLinkActive="active">Terms of Service</a></li>
            <!--<li><a class="text-muted">Copyrights</a></li>-->
          </ul>
        </div>
        <div class="col-6 col-md">
          <h5>Help</h5>
          <ul class="list-unstyled text-small">
            <li><a class="text-muted" href="mailto:service@photo-magic.nl">Contact Us</a></li>
            <li><a class="text-muted">FAQ</a></li>
            <!--<li><a class="text-muted">Order Status</a></li>-->
          </ul>
        </div>
        <!--
        <div class="col-6 col-md">
          <h5>Idea & Influence</h5>
          <ul class="list-unstyled text-small justify-center">
            <li><a class="text-muted" target="_blank">Image Credits goes to individual brands used in this site</a></li>
          </ul>
        </div>
        <div class="col-6 col-md">
          <h5>Server Side Technologies</h5>
          <ul class="list-unstyled text-small">
            <li><a class="text-muted">.Net Core</a></li>
            <li><a class="text-muted">Generic Repository Patterns</a></li>
            <li><a class="text-muted">Unit of Work Pattern</a></li>
            <li><a class="text-muted">Specification Pattern</a></li>
            <li><a class="text-muted">Azure Redis Cache</a></li>
            <li><a class="text-muted">Sqlite during devlopment</a></li>
            <li><a class="text-muted">Mysql in Production</a></li>
            <li><a class="text-muted">Swagger</a></li>
            <li><a class="text-muted">Stripe Implementation</a></li>
          </ul>
        </div>
        <div class="col-6 col-md">
          <h5>Client Side Technologies</h5>
          <ul class="list-unstyled text-small">
            <li><a class="text-muted">Angular 9</a></li>
            <li><a class="text-muted">Bootstrap 4</a></li>
            <li><a class="text-muted">ngx-bootstrap</a></li>
            <li><a class="text-muted">Font Awesome</a></li>
            <li><a class="text-muted">Lazy Loading</a></li>
            <li><a class="text-muted">Caching</a></li>
            <li><a class="text-muted">RXJS</a></li>
            <li><a class="text-muted">Design Components</a></li>
          </ul>
        </div>
        <div class="col-6 col-md">
          <h5>About</h5>
          <ul class="list-unstyled text-small">
            <li><a class="text-muted" href="https://myview.rahulnivi.net" target="_blank">Rahul Sahay</a></li>
            <li><a class="text-muted" href="https://twitter.com/rahulsahay19/" target="_blank"><i class="fa fa-twitter"> For Latest Updates</i></a></li>
            <li><a class="text-muted fa fa-github" href="https://github.com/rahulsahay19/eCommerce-App" target="_blank"> Give me a star</a> </li>
            <li><a class="text-muted" href="https://www.sportsjam.in/" target="_blank">Site Influence</a></li>
            <li><a class="text-muted" href="https://myview.rahulnivi.net" target="_blank">Rahul Sahay</a></li>
          </ul>
        </div>
        -->
      </div>
    </footer>
  </div>