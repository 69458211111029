<ngx-spinner>
  <h3>Fetching results...</h3>
</ngx-spinner>
<app-nav-bar></app-nav-bar>
<app-section-header></app-section-header>
<div>
  <!-- <h1>Welcome to {{ title }}</h1> -->
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>
