<div class="d-flex flex-column flex-md-row align-items-center justify-content-between p-3 px-md-4 mb-3 bg-white border-bottom shadow-sm fixed-top">
<div class="d-flex align-center">
    <img src="/assets/images/logo-2.png" style="max-height: 70px;" alt="myview" routerLink="/" class="logo" />
    <!--<h5 class="my-0 ml-md-2 font-weight-normal">Sports Center</h5> -->
</div>
  
  <nav class="my-2 my-md-0 mr-md-3 text-uppercase" style="font-size: larger;">
    <a class="p-2" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">[Home]</a>
    <a class="p-2" routerLink="/video" routerLinkActive="active">[Videos]</a>
    <a class="p-2" routerLink="/shop" routerLinkActive="active">[Photos]</a>
    <a class="p-2" routerLink="/personalize" routerLinkActive="active">[Personalized Service]</a>
    <!--<a class="p-2" routerLink="/shop" routerLinkActive="active">Blogs</a>    -->
    
    <!-- <a class="p-2" routerLink="/test-error" routerLinkActive="active">Test Error</a> -->
  </nav>
  <div class="d-flex align-center">
    <!--
    <a routerLink="/basket" class="position-relative">
      <i class="fa fa-shopping-cart fa-2x mr-5 text-dark"></i>
      <div *ngIf="(basket$ | async) as basket" class="cart-no">{{basket.items.length}}</div>
    </a>
    
    <ng-container *ngIf="(currentUser$ | async) === null">
      <a routerLink="/account/login" class="btn btn-outline-secondary mr-2">Login</a>
      <a routerLink="/account/register" class="btn btn-outline-secondary mr-3" href="#">Sign up</a>
    </ng-container>
    -->
    <ng-container *ngIf="currentUser$ | async">
      <div class="dropdown ml-3 mr-5" dropdown>
        <a class="dropdown-toggle" style="cursor: pointer;" dropdownToggle>
          <strong>Welcome {{(currentUser$ | async).displayName}}</strong>
        </a>
        <div class="dropdown-menu dropdown-menu-right" style="cursor: pointer;" *dropdownMenu>
          <a routerLink="/basket" class="dropdown-item d-flex align-items-center py-2">
            <i class="fa fa-shopping-cart mr-3"></i> View Basket
          </a>
          <a routerLink="/orders" class="dropdown-item d-flex align-items-center py-2">
            <i class="fa fa-history mr-3"></i> View Orders
          </a>
          <div class="dropdown-divider"></div>
          <a (click)="logout()" class="dropdown-item d-flex align-items-center py-2">
            <i class="fa fa-sign-out mr-3"></i> Sign Out
          </a>
        </div>
      </div>
    </ng-container>
  </div>
</div>
