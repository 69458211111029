<div class="container marketing">
    <hr class="featurette-divider">
  
    <div class="row featurette">
      <div class="col-md-10">
        <h3>What Information Do We Collect?</h3>
        <p>Personal Info: If you share your name or email with us, we keep that info safe.<br>
        Site Visiting Activities: We notice which parts of StockCake you visit and how you get there, like your IP address and which browser you are using.<br>
        Cookies: We use cookies (small files) to make the website work better for you.</p>
        
        <h3>Why Do We Collect Your Info?</h3>
        <p>To make our website better functional.<br>
        To tell you about changes or updates.<br>
        To help you use all the features we offer.<br>
        To answer your questions.<br>
        To keep an eye on any issues with website.<br>
        To send you news and info about stuff we think you will like.</p>
        
        <h3>Do We Share Your Info?</h3>
        <p>We do not sell your info to anyone. Sometimes we share general info (not personal stuff) with partners or advertisers to help improve our service.</p>
        
        <h3>Keeping Your Info Safe</h3>
        <p>We do our best to keep your info safe, but no online service is 100% secure. We use good tools to protect your information, but we can not promise it is totally safe.</p>
        
        <h3>Your Rights</h3>
        <p>You can look at, change, or ask us to delete your info. If you can not do it yourself in your account settings, just ask us for help.</p>
        
        <h3>Changes to This Policy</h3>
        <p>We might update this policy regularly. We will let you know about any big changes by posting the new version on our website.</p>
        
        <h3>Questions?</h3>
        <p>If you have any questions, you can email us at service@photo-magic.nl.</p>
      </div>
    </div>
      
</div>


